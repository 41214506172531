<template>
  <b-container fluid class="p-0 service-info-mapper h-100">
    <div v-if="groupID && serviceID && request">
      <b-row style="height: 50px" class="border-bottom justify-content-between service-info-mapper-header">
        <b-col cols="2" class="d-flex justify-content-center align-items-center cursor-pointer">
          <b-dropdown
            no-caret
            variant="outline-none"
            toggle-class="p-25 btn-none border-0 dropdown-chevron"
            menu-class="border"
            left
            :disabled="loadingInfo.enum_metodo"
          >
            <template #button-content>
              <div class="d-flex align-items-center">
                <b-skeleton
                  v-if="loadingInfo.enum_metodo"
                  class="rounded-pill mt-75 mr-25"
                  width="50px"
                  height="18px"
                />
                <b-badge
                  v-else
                  pill
                  :variant="getMethod(request.enum_metodo.id || 1, 'variant')"
                  class="mr-25"
                >
                  {{ getMethod(request.enum_metodo.id || 1, 'label') }}
                </b-badge>
                <feather-icon
                  icon="ChevronDownIcon"
                />
              </div>
            </template>
            <b-dropdown-item 
              v-for="item in methods" 
              :key="item.label" 
              class="request-method-option"
              @click="patchInfo(item.id, 'enum_metodo')"
            >
              <b-badge 
                pill
                :variant="item.variant"
              >
                {{ $t(item.label) }}
              </b-badge>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col cols="7" class="d-flex align-items-center p-0">
          <b-form-input
            v-if="endpoint != null"
            :ref="`endpoint-${requestID}`"
            class="no-decoration-input"
            v-model="endpoint"
            placeholder="Endpoint goes here!"
            :disabled="loadingInfo['endpoint']"
            @keydown.enter="$refs[`endpoint-${requestID}`].blur()"
            @blur="patchInfo(endpoint, 'endpoint')"
          />
          <b-form-input
            v-if="path != null"
            :ref="`path-${requestID}`"
            class="no-decoration-input"
            placeholder="Path goes here!"
            v-model="path"
            :disabled="loadingInfo['path']"
            @keydown.enter="$refs[`path-${requestID}`].blur()"
            @blur="patchInfo(path, 'path')"
          />
          <div
            class="d-flex align-items-center w-10 h-100 position-absolute"
            style="right: 0;"
            v-if="loadingInfo['endpoint'] || loadingInfo['path']"
          >
            <b-spinner
              style="width: 1rem; height: 1rem; opacity: 0.5"
            />
          </div>
        </b-col>
        <b-col cols="3" class="d-flex align-items-center justify-content-end">
          <b-button 
            v-if="!isWebhook"
            :disabled="isSendingExternal"
            class="d-flex no-wrap"
            variant="purple"
            @click="sendRequest">
            <feather-icon
              v-if="!isSendingExternal"
              class="mr-50"
              size="15"
              icon="ChevronsRightIcon"
            />
            <b-spinner
              v-else
              class="mr-50"
              style="width: 1rem; height: 1rem;"
            />
            {{ $t("integrator.pages.edit_service.tabs.operations.send") }}
          </b-button>
        </b-col>
      </b-row>
    <b-row>
      <b-col cols="12" class="p-0 m-0" >
        <div class="d-flex nav-items" style="height: 50px">
          <div
            v-for="(item, index) in navItems" :key="item.title"
            class="h-100 d-flex align-items-center justify-content-center cursor-pointer px-1 w-50 border-bottom"
            :class="active[index] ? 'text-light border border-bottom-0 border-top-0' : ''"
            @click="setActive(index)"
          >
            <span class="d-flex text-truncate text-capitalize" v-if="!loadingInfo[item.field]">
              {{ $t(item.title) }}&nbsp;
              <span style="font-size: 10px">
                {{ getTabCount(item) }}
              </span>
            </span>
            <b-skeleton
              v-else
              class="mt-1"
              width="70px"
              height="20px"
            />
            <b-dropdown
              no-caret
              variant="outline-none"
              v-if="item.options"
              toggle-class="p-25 btn-none border-0 dropdown-chevron"
              :offset="-78"
              :disabled="loadingInfo[item.field]"
            >
              <template #button-content>
                <feather-icon
                  v-if="!loadingInfo[item.field]"
                  size="17"
                  icon="ChevronDownIcon"
                />
                <b-spinner
                  v-else
                  class="ml-50"
                  style="width: 1rem; height: 1rem; opacity: 0.5"
                />
              </template>
              <b-dropdown-item v-for="navOptionItem in item.options" :key="navOptionItem.id"  @click="item.action(navOptionItem)">
                {{ $t(navOptionItem.label) }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <hr class="w-100 position-relative mt-4"/>
        </div>
      </b-col>
    </b-row>
    <div
      v-if="active[0] && body != null && !loadingInfo['enum_body_type']"
    >
      <div v-if="bodyField === false" class="text-center mt-2 font-weight-bolder">This Request has no body</div>
      <body-editor
        v-else-if="bodyField == 'schema'"
        :key="`${version}${direction}-mapper-body`"
        :ref="`${version}${direction}-mapper-body`"
        class="mt-1"
        :language="bodyType.value"
        :bodyFromState="bodyFromState"
        v-model="body"
        @save="patchBody"
      />
      <div
        class="w-100"
        v-else-if="bodyField == 'fields'"
      >
        <vue-perfect-scrollbar style="max-height: 500px;">
          <mapper
            class="mt-1"
            :key="`${request.id}-request-mapper-body`"
            :serviceID="serviceID"
            :groupID="groupID"
            :requestID="requestID"
            :field_type="getFieldTypeEnumID('body')"
            :enumSetupType="enumSetupType"
            type="body"
            :direction="direction"
          />
        </vue-perfect-scrollbar>
      </div>
    </div>
    <div v-else-if="active[0] && loadingInfo['enum_body_type']">
      <b-skeleton
        v-for="i in Math.max(8, Math.floor(12 * Math.random()))" :key="`body-skeleton-${i}`"
        :width="`${Math.max(Math.floor(400 * Math.random()), 50)}px`"
        height="23px"
        class="mt-2 ml-50"
      />
    </div>
      <div
        class="w-100"
        v-if="active[1]"
        style=""
      >
        <vue-perfect-scrollbar style="max-height: 500px;">
          <mapper
            class="mt-1"
            :key="`${request.id}-request-mapper-queries`"
            :serviceID="serviceID"
            :groupID="groupID"
            :requestID="requestID"
            :field_type="getFieldTypeEnumID('query')"
            :enumSetupType="enumSetupType"
            type="queries"
            :direction="direction"
          />
        </vue-perfect-scrollbar>
      </div>
      <div
        class="w-100"
        v-if="active[2]"
        style=""
      >
        <vue-perfect-scrollbar style="max-height: 500px;">
          <mapper
            class="mt-1"
            :key="`${request.id}-request-mapper-headers`"
            :serviceID="serviceID"
            :groupID="groupID"
            :requestID="requestID"
            :field_type="getFieldTypeEnumID('header')"
            :enumSetupType="enumSetupType"
            type="headers"
            :direction="direction"
          />
        </vue-perfect-scrollbar>
      </div>
    </div>
    <div v-else class="h-100">
      <b-row style="height: 50px" class="border-bottom service-info-mapper-header">
        <b-col cols="12" class="d-flex align-items-center justify-content-end">
          <b-button variant="purple" class="d-flex no-wrap cursor-not-allowed" disabled>
            <feather-icon
              class="mr-50"
              size="15"
              icon="ChevronsRightIcon"
            />
            {{ $t("integrator.pages.edit_service.tabs.operations.send") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="h-75">
        <b-col cols="12" class="d-flex justify-content-center placeholder-request h-100">
          <div class="w-75 d-flex flex-column text-center h-100 justify-content-center">
            <feather-icon
              icon="AlertTriangleIcon"
              size="64"
              class="alert-icon align-self-center mb-2"
            />
            <span class="main-text my-1">
              {{ $t("integrator.pages.edit_service.tabs.operations.mapper_wrapper.inform_request") }}
            </span>
            <span class="info-text">
              {{ $t("integrator.pages.edit_service.tabs.operations.mapper_wrapper.description") }}
            </span>
            <b-link class="info-link cursor-pointer my-2" target="_blank" href="https://fiqon.readme.io/docs">
              <u>{{ $t("integrator.pages.edit_service.tabs.operations.mapper_wrapper.about") }}</u>
              <feather-icon
                class="ml-25 "
                icon="ExternalLinkIcon"
              />
            </b-link >
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BBadge,
  BFormInput,
  BButton,
  BNav,
  BNavItem,
  BDropdownItem,
  BNavItemDropdown,
  BDropdown,
  BLink,
  BSpinner,
  BSkeleton
} from 'bootstrap-vue'
import BodyTypes from '@/custom/class/Enum/BodyTypes.js'
import Mapper from '@/views/pages/integrator/components/EditService/Endpoint/Mapper.vue'
import BodyEditor from '@/views/pages/integrator/components/EditService/Endpoint/BodyEditor.vue'
import Methods from '@/custom/class/Enum/Methods'
import { mapGetters } from 'vuex'
import EndpointSetupType from '@/views/pages/integrator/components/EditService/Endpoint/class/Enum/EndpointSetupType.js'
import { makeToast } from '@/layouts/components/Popups.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  export default {
    components: {
      BContainer,
      BRow,
      BCol,
      BBadge,
      BFormInput,
      BButton,
      BNav,
      BDropdownItem,
      BNavItemDropdown,
      BNavItem,
      BDropdown,
      Mapper,
      BodyEditor,
      BLink,
      BSpinner,
      VuePerfectScrollbar,
      BSkeleton
    },
    props: {
      serviceID: {
        type: [ Number, String ],
        required: true
      },
      groupID: {
        type: [ Number, String ],
        default: null
      },
      requestID: {
        type: [ Number, String ],
        default: null
      },
      enumSetupType: {
        type: [ Number, String ],
        required: true
      }
    },
    data() {
      return {
        endpoint: null,
        path: null,
        navItems: null,
        active: [ true, false, false ],
        field_type: {
          sent: {
            header: 1,
            query: 2,
            body: 3
          },
          received: {
            header: 5,
            query: 6,
            body: 4
          }
        },
        dir: 'sent',
        body: null,
        bodyType: null,
        version: 0,
        setupType: null,

        isSendingExternal: false,
        loadingInfo: new Object()
      }
    },
    computed: {
      ...mapGetters('serviceModule', ['getIntegratorServiceByID']),
      ...mapGetters('requestModule', ['getGroupRequest']),
      ...mapGetters('webhookModule', ['getGroupWebhook']),
      request() {
        if (!this.setupType) return

        return this[this.setupType.requestGetter](this.serviceID, this.groupID, this.requestID)
      },
      enumBodyItems() {
        return new BodyTypes().items
      },
      methods() {
        return new Methods().items
      },
      bodyField() {
        if (!this.bodyType) return

        switch (true) {
          case this.bodyType.has_fields: {
            return 'fields'
          } break;
          case this.bodyType.has_schema: {
            return 'schema'
          } break;
          default: {
            return false
          }
        }
      },
      host() {
        return this.getIntegratorServiceByID(this.orgID, this.serviceID)
      },
      bodyFromState() {
        if (this.bodyField == 'schema') {
          return this.request.body[this.direction][this.bodyField]
        }
      },
      direction() {
        if (!this.setupType) return

        return this.setupType.direction || this.dir
      },
      isWebhook() {
        if (!this.setupType) return

        return this.setupType.type == 'webhook'
      },
      getRequestMethod() {
        if (!this.request.enum_metodo) return
        
        return this.request.enum_metodo.script_output.toLowerCase()
      },
      getBodyType() {
        return this.enumBodyItems.find((el) => el.id == this.request.body.sent.type.id)
      },
      orgID() {
        return localStorage.getItem('selectedOrganization')
      }
    },
    mounted () {
      this.init();
    },
    methods: {
      init() {
        this.setSetupType()
        this.setActive(1)
        this.$nextTick(() => {
            this.initializeInfo()
        })
      },
      setSetupType() {
        let typeItems = new EndpointSetupType().items
        this.setupType = typeItems.find(el => el.id == this.enumSetupType)
      },
      initializeInfo() {
        if (this.request && Object.keys(this.request).length) {
          switch(this.setupType.type) {
            case 'request': {
              this.endpoint = this.request.endpoint || ''
            } break;
            case 'webhook': {
              this.path = this.request.path || ''
            } break;
          }
          this.setBody()
        }
      },
      initializeItems() {
        this.navItems = [
          {
            title: this.bodyType.label,
            options: this.enumBodyItems,
            action: this.patchBodyType,
            field: 'enum_body_type'
          },
          {
            title: 'Queries',
            key: 'queries',
            direction: this.direction
          },
          {
            title: 'Headers',
            key: 'headers',
            direction: this.direction
          },
        ]
      },
      setBody() {
        let typeID = this.request.body[this.direction].type.id

        this.bodyType = this.getEnumBodyTypeByID(typeID) || false

        if (!this.body) {
          this.body = this.request.body[this.direction].schema
        }
        
        this.$nextTick(() => {
          this.initializeItems()
        })
      },
      patchBodyType(item) {
        this.$set(this.loadingInfo, 'enum_body_type', true)
        this.$store.dispatch(`${this.setupType.namespace}patchBodyType`,
          {
            serviceID: this.serviceID,
            groupID: this.groupID,
            requestID: this.requestID,
            direction: this.direction,
            new_value: item.id,
            field: 'enum_body_type'
          }).then(() => {
            this.version++
            this.$nextTick(() => {
              this.setBody()
            })
            this.$set(this.loadingInfo, 'enum_body_type', false)
          }).catch((err) => {
            console.log(err)
            this.$set(this.loadingInfo, 'enum_body_type', false)
          })
      },
      patchInfo(value, field) {
        if (this.request[field] && (value == this.request[field] || value == this.request[field].id || this.request[field] == '')) return
        this.$set(this.loadingInfo, field, true)

        this.$store.dispatch(`${this.setupType.namespace}${this.setupType.patchAction}`,
          {
            field: field,
            serviceID: this.serviceID,
            requestID: this.requestID,
            groupID: this.groupID,
            new_value: value
          }).then((resp) => {
            this.$set(this.loadingInfo, field, false)
          }).catch((err) => {
            this.$set(this.loadingInfo, field, false)
          })
      },
      patchBody() {
        this.$store.dispatch(`${this.setupType.namespace}patchBody`,
          {
            serviceID: this.serviceID,
            groupID: this.groupID,
            requestID: this.requestID,
            direction: this.direction,
            new_value: this.body,
            field: this.bodyField,
            request_field: 'body_schema'
          }).then((resp) => {
            this.initializeInfo()
            this.$refs[`${this.version}${this.direction}-mapper-body`].setIsSaving(false)
          }).catch((err) => {
            this.initializeInfo()
            this.$refs[`${this.version}${this.direction}-mapper-body`].setIsSaving(false)
          })
      },
      async sendRequest() {
        let req = this.request

        let payload = {
          method: this.getRequestMethod,
          endpoint: this.getIntegratorServiceByID(this.orgID, this.serviceID).host + this.buildRequestUrl(req.endpoint, req.queries.sent),
          body_type: this.getBodyType,
          body: await this.getBody(),
          headers: this.buildHeaders(req.headers.sent),
        }

        this.isSendingExternal = true

        this.$store.dispatch('requestModule/sendExternalRequest', payload).then((resp) => {
          
          this.isSendingExternal = false
          
          this.$emit('mapReceived', resp)

        }).catch((err) => {
          console.log(err)
          this.isSendingExternal = false
          makeToast({
            title: this.$t('common.toast.error.title'),
            text: this.$t('common.toast.error.message'),
            variant: 'danger',
            icon: "XIcon"
          })
        })
      },
      setBodyType(param, by) {
        this.bodyType = this.enumBodyItems.find((el) => el[by] == param)
      },
      buildHeaders(headers) {
        let headers_obj = {}

        headers.forEach((el) => {
          headers_obj[el.field_key] = el.example_value
        })

        return headers_obj
      },  
      async getBody() {
        let body_type = await this.enumBodyItems.find((el) => el.id == this.request.body.sent.type.id)

        switch (true) {
          case body_type.has_fields: {
            return this.request.body.sent.fields
          }
          case body_type.has_schema: {
            return this.request.body.sent.schema
          }
          default: {
            return null;
          }
        }
      },
      buildRequestUrl(endpoint, queries) {
        let has_params = false;

        queries.forEach((el) => {
          if(el.example_value != null || el.example_value != undefined) {
            if(has_params) {
              endpoint += "&";
            } else {
              endpoint += "?";
            }
            

            el.example_value ? endpoint += `${el.field_key}=${el.example_value}` : '';

            has_params = true;
          }
        });

        endpoint = endpoint.replaceAll('\t', '')

        console.log(endpoint);
        return endpoint;
      },
      setActive(index) {
        this.active.forEach((_, key) => {
          this.$set(this.active, key, index == key)
        })
      },
      getMethod(enum_id, field) {
        return this.methods[enum_id - 1][field]
      },
      getTabCount(item) {
        const { key, direction } = item
        if (key && this.request[key]) {
          let length = Math.max(this.request[key][direction].length - 1, 0)
          return length ? `(${length})` : ''
        }
        return
      },
      getEnumBodyTypeByID(id) {
        return this.enumBodyItems.find((el) => el.id == id)
      },
      getFieldTypeEnumID(field) {
        return this.field_type[this.direction][field]
      }
    },
  }
</script>

<style lang="scss">
.dropdown-chevron {
  color: #B4B7BD !important;
}

.request-method-option {
  > .dropdown-item {
    padding: 7px 10px;
    &:hover, &:active, &:focus {
      background: #343d55 !important;
    }
  }
}
</style>

<style lang="scss" scoped>

.service-info-mapper {
  .service-info-mapper-header {
    background-color: #343D55 !important;
  }
  .alert-icon {
    opacity: 0.35 !important;
  }

  .placeholder-request {
    .main-text {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #B4B7BD;
    }
    .info-text {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #B4B7BD;
    }
    .info-link {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.4px;
      color: #B4B7BD;
    }
  }
}
</style>