<template>
  <b-nav pills>
    <b-nav-item 
      v-for="(item, index) in navList"
      :key="item.label"
      :active="activeList[index]" 
      class="integrator-nav-item text-nowrap mr-50"
      @click="setActive(index)"
    >
      <feather-icon
        size="16"
        :icon="item.icon"
      />
      <span class="integrator-nav-item-title">
        {{ $t(`integrator.service.edit.nav.${item.label}`) }}
      </span>
    </b-nav-item>
  </b-nav>
</template>

<script>
import {
  BNav,
  BNavItem
} from 'bootstrap-vue'
import { indexOf } from 'postcss-rtl/lib/affected-props'

  export default {
    components: {
      BNav,
      BNavItem
    },
    props: {
      value: {
        type: Array,
        required: true
      },
      defaultActive: {
        type: [ Number, String ],
        default: 2,
      }
    },
    data() {
      return {
        
      }
    },
    computed: {
      navList() {
        return [
          { label: 'general', icon: 'InfoIcon' },
          { label: 'dashboard', icon: 'FolderIcon' },
          { label: 'requests', icon: 'GitPullRequestIcon' },
          { label: 'webhooks', icon: 'LinkIcon' }, 
          { label: 'releases', icon: 'SendIcon' }, 
        ]
      },
      activeList: {
        get() {
          return this.value
        }, 
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    mounted () {
      if (!this.activeList.length) {
        this.init();
      }
    },
    methods: {
      init() {
        let key
        let hash = location.hash
        hash = hash.replaceAll('#', '')
        key = this.navList.findIndex(el => el.label==hash)
        if (key<0) key = 0

        this.navList.forEach((_, index) => {
          this.activeList.push((key === index))
        })
      },
      setActive(index) {
        this.activeList.forEach((_, key) => {
          this.$set(this.activeList, key, key === index)
        })
        location.hash = this.navList[index].label
      }
    }
  }
</script>

<style lang="scss" scoped>
.integrator-nav-item {
  .integrator-nav-item-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px !important;
    /* identical to box height */

    letter-spacing: 0.4px;
  }
}

.active {
  background-color: #7367f0 !important;
  border-color: #7367f0 !important;
  box-shadow: 0 4px 18px -4px #7367f0 !important;
}

</style>