<template>
  <div>
    <template v-if="filteredRequest.length">
      <b-row
        v-for="(endpoint, index) in filteredRequest"
        :key="`${index}-${groupID}-${version}`"
        class="pl-1 m-0 py-25 folder-item-request d-flex align-items-center flex-nowrap"
        :class="selected == index ? 'highlight' : ''"
      >
        <div class="set-width-90" @click="openRequest(endpoint, index)">
          <b-badge :variant="getMethod(endpoint.enum_metodo.id, 'variant')" pill class="px-50 py-0 folder-item-request-method">
            {{ getMethod(endpoint.enum_metodo.id, 'abbreviation') }}
          </b-badge>
          <span v-if="editingIndex != index" class="ml-50 folder-item-request-name">
            {{ shorttenedString(endpoint.name, 17) }}
          </span>
          <b-form-input
            v-else
            :ref="getID(`request-input-${index}`)"
            class="folder-item-request-input d-inline ml-50 p-50"
            v-model="editingName"
            @keydown.enter="editName(index)"
            @blur="editName(index); editingIndex = null"
          />
        </div>
        <b-dropdown
          :id="getID('folder-item-menu-dropdown')"
          :ref="getID('folder-item-menu-dropdown')"
          no-caret
          variant="outline-none"
          toggle-class="py-0 folder-item-dropdown integrator-folder-nav-dropdown d-inline"
          right
          menu-class="bg-light"
          :disabled="loadingItemObj[index]"
        >
          <template #button-content>
            <b-spinner
              v-if="loadingItemObj[index]"
              style="width: 1rem; height: 1rem"
            />
            <feather-icon
              v-else
              class="folder-item-request-dots"
              icon="MoreVerticalIcon"
              size="16"
            />
          </template>
          <template #default>
            <div v-for="item in options" :key="item.label">
              <b-dropdown-item
                v-if="!item.divider"
                class="folder-item-dropdown-item integrator-folder-nav-dropdown-item"
                @click="item.action(endpoint, index, null);"
              >
                <feather-icon
                class="mr-50"
                :icon="item.icon"
                size="20"
                />
                {{ $t(item.label) }}
              </b-dropdown-item>
              <b-dropdown-divider v-else/>
            </div>
          </template>
        </b-dropdown>
      </b-row>
    </template>
    <div v-for="i in creatingItemArr.length" :key="`folder-item-skeleton-${i}`" class="d-flex align-items-center pl-75" style="height: 34px">
      <b-skeleton
        class="ml-25 mt-50 rounded-pill"
        height="20px"
        width="15%"
      />
      <b-skeleton
        class="ml-75 mt-50"
        height="20px"
        width="75%"
      />
    </div>
    <i class="pl-1 folder-item-request-add-request" @click="addRequest">
      + {{$t('integrator.pages.edit_service.tabs.operations.add_new_request')}}
    </i>
  </div>
</template>

<script>
import {
  BRow,
  BCollapse,
  BBadge,
  BDropdown, 
  BDropdownItem,
  BDropdownDivider,
  BFormInput,
  BSkeleton,
  BSpinner
} from 'bootstrap-vue';
import Methods from '@/custom/class/Enum/Methods';
import { makeToast } from '@/layouts/components/Popups'
import { v4 as uuidv4 } from 'uuid'
import EndpointSetupType from '@/views/pages/integrator/components/EditService/Endpoint/class/Enum/EndpointSetupType.js'

  export default {
    components: {
      BRow,
      BCollapse,
      BBadge,
      BDropdown, 
      BDropdownItem,
      BDropdownDivider,
      BFormInput,
      BSkeleton,
      BSpinner
    },
    props: {
      serviceID: {
        type: [ Number, String ],
        required: true,
      },
      groupID: {
        type: [ Number, String ],
        required: true,
      },
      value: {
        type: Array,
        required: true
      },
      enumSetupType: {
        type: [ Number, String ],
        required: true
      }
    },
    data() {
      return {
        version: 0,
        editingName: '',
        editingIndex: null,
        selected: null,
        uuidMap: {},

        filteredRequest: [],
        setupType: null,

        creatingItemArr: new Array(),
        loadingItemObj: new Object(),
        eopae: false
      }
    },
    computed: {
      requests: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      methods() {
        return new Methods().items
      },
      options() { 
        return [
          { label: 'New Request', icon: 'PlusCircleIcon', action: this.addRequest },
          { divider: true },
          { label: 'Edit Name', icon: 'EditIcon', action: this.showEdit },
          { label: 'Duplicate', icon: 'CopyIcon', action: this.cloneRequest },
          { label: 'Delete', icon: 'TrashIcon', action: this.deleteRequest },
        ]
      }
    },
    mounted () {
      this.init();
    },
    methods: {
      init() {
        this.setSetupType()
        this.$nextTick(() => {
          this.setFilteredRequest()
        })
        this.$emit('isMounted')
      },
      setSetupType() {
        let typeItems = new EndpointSetupType().items
        this.setupType = typeItems.find(el => el.id == this.enumSetupType)
      },
      setFilteredRequest() {
        this.filteredRequest = this.requests
      },
      getMethod(enum_id, field) {
        return this.methods[enum_id -1][field]
      },
      openRequest(item, index) {
        this.setSelectedItem(index)
        this.$emit('setSelected', item)
      },
      setSelectedItem(index) {
        this.selected = index
      },
      filterRequest(string) {
        this.filteredRequest = this.requests.filter((el) => {
          return el.name.toLowerCase().indexOf(string.toLowerCase()) > -1;
        });
      },
      addRequest(item, _, isClone) {
        this.creatingItemArr.push(true)

        let data = isClone ? item : new Object()

        this.$store.dispatch(`${this.setupType.namespace}${this.setupType.addAction}`, { serviceID: this.serviceID, groupID: this.groupID, data }).then(() => {
          let index = this.requests.length - 1
          
          this.$nextTick(() => {
            this.creatingItemArr.pop()

            this.showEdit(this.requests.at(-1), index)
            this.openRequest(this.requests[index], index)
          })
          
          this.version++
        }).catch(() => {
          makeToast({
            title: 'Error creating',
            text: 'F try again',
            variant: 'danger',
            icon: 'XIcon'
          })
        })
      },
      cloneRequest(item, index) {
        this.addRequest({ ...item, name: `${item.name}_copy` }, index, true)
      },
      deleteRequest(item, index) {
        this.$bvModal
          .msgBoxConfirm(`Are you sure you want to delete "${item.name}" request?`,
            {
              title: "Delete request",
              okTitle: "Ok",
              okVariant: 'outline-danger',
              cancelTitle: 'Cancel',
              cancelVariant: "success",
              size: "sm",
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.$set(this.loadingItemObj, index, true)
              this.$store.dispatch(`${this.setupType.namespace}${this.setupType.deleteAction}`, 
                {
                  requestID: item.id,
                  serviceID: this.serviceID,
                  groupID: this.groupID,
                }
              ).then(() => {
                this.version++
                this.$set(this.loadingItemObj, index, false)
                
              }).catch((err) => {
                makeToast({
                  title: "Error",
                  text: `Could not delete "${item.name}""`,
                  variant: "danger",
                  icon: "XIcon"
                })
                this.$set(this.loadingItemObj, index, false)
              })
            }
          })
      },
      showEdit(item, index) {
        this.editingIndex = index
        this.editingName = item.name
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs[this.getID(`request-input-${index}`)][0].select()
          }, 10)
        })
      },
      editName(index) {

        if (this.editingName == this.requests[this.editingIndex].name) {
          this.editingIndex = null
          return
        }
        this.editingIndex = null

        this.$set(this.loadingItemObj, index, true)

        this.$store.dispatch(`${this.setupType.namespace}${this.setupType.patchAction}`,
          {
            field: 'name',
            requestID: this.requests[index].id,
            serviceID: this.serviceID,
            groupID: this.groupID,
            new_value: this.editingName
          }
        ).then(() => {
          this.$set(this.requests[index], 'name', this.editingName)
          this.$set(this.loadingItemObj, index, false)

        }).catch((err) => {
          makeToast({
            title: "Error",
            text: `Could not edit "${item.name}""`,
            variant: "warning",
            icon: "TriangleIcon"
          })
          this.$set(this.loadingItemObj, index, false)
        })
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      shorttenedString(str, size) {
        if(str.length > size) {
          str = str.substring(0, size) + '...';
        }
        return str
      },
    },
  }
</script>

<style lang="scss" scoped>
.folder-item-request {
  transition: all 0.1s ease;
  
  .folder-item-request-dots {
    opacity: 0;
    transition: all 0.2s ease;
  }
  &.highlight {
    background-color: #343D55;
    transition: all 0.1s ease;
    .folder-item-request-name {
      color: #FFFFFF;
      transition: all 0.2s ease;
    }
    .folder-item-request-dots {
      color: #FFFFFF;
      opacity: 1;
      transition: all 0.2s ease;
    }
  }
  &:hover {
    background-color: #343D55;
    transition: all 0.1s ease;
    .folder-item-request-name {
      color: #FFFFFF;
      transition: all 0.2s ease;
    }
    .folder-item-request-dots {
      color: #FFFFFF;
      opacity: 1;
      transition: all 0.2s ease;
    }
  }
  .folder-item-request-method {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  .folder-item-request-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;
    color: #676D7D;
  }
}
.folder-item-request-add-request {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: #676D7D;
  &:hover {
    color: #FFFFFF;
  }
}
</style>

<style lang="scss">
.integrator-folder-nav-dropdown  {
  padding: 0px 0px;
}

.folder-item-dropdown-item {
  > .dropdown-item {
    padding: 5px 10px;
    color: #6E6B7B !important;
    &:hover, &:active, &:focus {
      color: #7367F0!important;
      background: #7367f01f !important;
    }
  }
}

.folder-item-request-input {
  height: 20px !important;
  width: 60% !important;
}

.set-width-90 {
  width: 90% !important;
}
</style>