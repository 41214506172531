<template>
  <b-container fluid class="px-25 mapper-list">
    <b-row no-gutters v-if="items && items.length">
      <b-col cols="3" class="px-25">
        <span class="mapper-label">Key</span>
      </b-col>
      <b-col cols="5" class="px-25 mapper-label">
        <span class="mapper-label">Value</span>
      </b-col>
      <b-col cols="3" class="px-25 mapper-label">
        <span class="mapper-label">Type</span>
      </b-col>
      <b-col cols="1" class="px-25 mapper-label">
        <span class="mapper-label">Actions</span>
      </b-col>
    </b-row>
    <b-row v-else class="d-flex justify-content-center" no-gutters>
      <small class="text-success"><strong>Try adding some {{ $t(this.type) }} </strong></small>
    </b-row>
    <b-row v-for="(item, index) in items" :key="item.label" class="my-25 mapper-input-list" no-gutters>
      <b-col cols="3" class="pl-25">
        <b-form-input
          :key="getID(`request-item-field_key-${index}`)"
          :ref="getID(`request-item-field_key-${index}`)"
          v-model="items[index].field_key"
          :disabled="isLoadingInput(item.id, 'field_key')"
          @blur="patchRequestItem(index, 'field_key')"
          @keydown.enter="patchRequestItem(index, 'field_key')"
        />
        <b-spinner
          type="grow"
          v-if="isLoadingInputEdit(item.id, 'field_key')"
          style="width: 0.5rem; height: 0.5rem; right: 5px; top:12%; z-index: 2"
          class="position-absolute"
        />
      </b-col>
      <b-col cols="5" class="pl-25">
        <b-form-input
          :key="getID(`request-item-example_value-${index}`)"
          :ref="getID(`request-item-example_value-${index}`)"
          v-model="items[index].example_value"
          :disabled="isLoadingInput(item.id, 'example_value')"
          @blur="patchRequestItem(index, 'example_value')"
          @keydown.enter="patchRequestItem(index, 'field_key')"
        />
        <b-spinner
          type="grow"
          v-if="isLoadingInputEdit(item.id, 'example_value')"
          style="width: 0.5rem; height: 0.5rem; right: 5px; top:12%; z-index: 2"
          class="position-absolute"
        />
      </b-col>
      <b-col cols="3" class="pl-25">
        <b-form-select
          :key="getID(`request-item-data_type-${index}`)"
          v-model="items[index].enum_data_type"
          text-field="label"
          :disabled="isLoadingInput(item.id, 'enum_data_type')"
          @change="patchRequestItem(index, 'enum_data_type')"
        >
          <b-form-select-option
            v-for="dataType in dataTypes" :key="`${dataType.label}-${dataType.id}`"
            :value="dataType"
            >
            {{ dataType.label }}
          </b-form-select-option>
        </b-form-select>
        <b-spinner
          type="grow"
          v-if="isLoadingInputEdit(item.id, 'enum_data_type')"
          style="width: 0.5rem; height: 0.5rem; right: 5px; top:12%; z-index: 2"
          class="position-absolute"
        />
      </b-col>
      <b-col cols="1" class="d-flex align-items-center pl-25" v-if="!loadingDeleteObj[item.id]">
        <feather-icon
          :key="getID(`description-icon-${index}`)"
          :id="getID(`description-icon-${index}`)"
          size="18"
          icon="FileTextIcon"
          class="cursor-pointer description-icon-button"
          @click="setPopoverOpen(true, index)"
        />
        <b-popover
          :key="getID(`description-popover-${index}`)"
          triggers="focus"
          variant="purple"
          offset="110"
          :target="getID(`description-icon-${index}`)"
          :title="$t('Description')"
          placement="bottom"
          :show.sync="popoverOpen[index]"
        >
          <b-container fluid class="p-0">
            <b-row class="mb-50">
              <b-col cols="12">
                <b-form-textarea
                  autofocus
                  v-model="items[index].note"
                  @keydown.enter.exact="(e) => submitNote(e, index)"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="d-flex justify-content-end">
                <b-button class="px-50 py-25" variant="purple" @click="(e) => submitNote(e, index)" :disabled="noteButtonDisabled[index]">
                  {{ $t('Save')}}
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-popover>
        <feather-icon
          size="18"
          class="delete-icon-button cursor-pointer"
          icon="TrashIcon"
          @click="verifyDeleteRow(index)"
        />
      </b-col>
      <b-col cols="1" class="d-flex align-items-center pl-25" v-else>
        <b-spinner
          class="ml-75"
          style="width: 1.2rem; height: 1.2rem;"
        />
      </b-col>
    </b-row>
    <b-row v-for="(_, index) in loadingAddArr" :key="`loader-list-add-${index}`" class="my-25 mapper-input-list" no-gutters>
      <b-col cols="3" class="pl-25">
        <b-skeleton
          animation="fade"
          height="38px"
          class="w-100 m-0"
        />
      </b-col>
      <b-col cols="5" class="pl-25">
        <b-skeleton
          animation="fade"
          height="38px"
          class="w-100 m-0"
        />
      </b-col>
      <b-col cols="3" class="pl-25">
        <b-skeleton
          animation="fade"
          height="38px"
          class="w-100 m-0"
        />
      </b-col>
      <b-col cols="1" class="d-flex align-items-center pl-25">
        <b-skeleton
          animation="fade"
          height="25px"
          class="w-75 m-0"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="defaultItem"
      class="my-25"
      :class="newItemFocus ? 'mapper-input-list' : 'mapper-input-plus'"
      no-gutters
    >
      <!-- <b-col cols="3" class="pl-25">
        <b-form-input
          v-model="defaultItem.key"
          @focus="setFocus(true)"
          @blur="setFocus(false)"
          @keydown.enter="addRow"
        />
      </b-col>
      <b-col cols="5" class="pl-25">
        <b-form-input
          v-model="defaultItem.value"
          @focus="setFocus(true)"
          @blur="setFocus(false)"
          @keydown.enter="addRow"
        />
      </b-col>
      <b-col cols="3" class="pl-25">
        <b-form-select
          v-model="defaultItem.data_type"
          text-field="label"
        >
          <b-form-select-option
            v-for="dataType in dataTypes" :key="`${dataType.label}-${dataType.id}`"
            :value="dataType"
          >
            {{ dataType.label }}
          </b-form-select-option>
        </b-form-select>
      </b-col> -->
      <b-col cols="12" class="pl-25 mt-50 d-flex justify-content-start">
        <b-badge variant="light-light" class="cursor-pointer border ml-50" @click="addRow">
          Add {{ $t(type) }}
          <feather-icon
            size="25"
            icon="PlusCircleIcon"
            class="text-light"
          />
        </b-badge>
      </b-col>
    </b-row>
    
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BDropdown,
  BFormSelect,
  BFormSelectOption,
  BPopover,
  BButton,
  BFormTextarea,
  BSkeleton,
  BSpinner,
  BBadge
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import DataTypes from '@/custom/class/Enum/DataType.js'
import FieldTypes from '@/custom/class/Enum/FieldType.js'
import { v4 as uuidv4 } from 'uuid'
import EndpointSetupType from '@/views/pages/integrator/components/EditService/Endpoint/class/Enum/EndpointSetupType.js'

  export default {
    components: {
      BContainer,
      BRow,
      BCol,
      BFormInput,
      BDropdown,
      BFormSelect,
      BFormSelectOption,
      BPopover,
      BButton,
      BFormTextarea,
      BSkeleton,
      BSpinner,
      BBadge
    },
    props: {
      serviceID: {
        type: [ String, Number ],
        required: true
      },
      groupID: {
        type: [ String, Number ],
        requried: true
      },
      requestID: {
        type: [ String, Number ],
        required: true
      },
      direction: {
        type: String,
        required: true
      },
      field_type: {
        type: Number,
        required: true
      },
      type: {
        type: String,
        required: true
      },
      enumSetupType: {
        type: [ String, Number ],
        required: true
      }
    },
    data() {
      return {
        newItemFocus: false,
        defaultItem: null,

        uuidMap: {},
        popoverOpen: {},
        noteButtonDisabled: {},
        setupType: null,
        loadingAddArr: new Array(),
        disableAdd: false,
        loadingDeleteObj: new Object(),
        loadingEditObj: new Object(),
      }
    },
    computed: {
      ...mapGetters('requestModule', ['getRequestItems']),
      ...mapGetters('webhookModule', ['getWebhookItems']),
      items() {
        if (!this.setupType) return

        return this[this.setupType.getItems](
          {
            serviceID: this.serviceID,
            groupID: this.groupID,
            requestID: this.requestID,
            direction: this.direction,
            type: this.type,
          }
        )
      },
      fieldTypes() {
        return new FieldTypes().items
      },
      dataTypes() {
        return new DataTypes().items
      },
    },
    mounted () {
      this.init();
    },
    methods: {
      init() {
        this.setSetupType()
        this.$nextTick(() => {
          this.setupDefaultItem()
        })
      },
      setSetupType() {
        let typeItems = new EndpointSetupType().items
        this.setupType = typeItems.find(el => el.id == this.enumSetupType)
      },
      setupDefaultItem() {
        this.defaultItem = {
          key: '',
          value: '',
          required: false,
          field_type: this.getFieldTypeByID(this.field_type),
          data_type: this.getDataTypeByID(1),
          note: '',
        }
      },
      addRow(payload = {}, type = null) {
        
        if (this.disableAdd) return
        this.timerAdd()

        this.loadingAddArr.push(true)
        this.$store.dispatch(`${this.setupType.namespace}${this.setupType.addItem}`,
          {
            serviceID: this.serviceID,
            groupID: this.groupID,
            requestID: this.requestID,
            required: this.defaultItem.required,
            key: payload.field_key || this.defaultItem.key,
            field_type: payload.enum_field_type || this.defaultItem.field_type,
            data_type: payload.enum_data_type || this.defaultItem.data_type,
            value: payload.example_value || this.defaultItem.value,
            note: payload.note || this.defaultItem.note,
            direction: this.direction,
            type: type || this.type,
          }
        ).then(() => {
          this.loadingAddArr.pop()
          this.setupDefaultItem()
        }).catch((err) => {
          console.error(err)
          this.loadingAddArr.pop()
        })
      },
      verifyDeleteRow(index) {
        let isRowEmpty = this.items[index].field_key || this.items[index].example_value

        if (isRowEmpty) {
          this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this ${this.type}`,
            {
              title: `Delete ${this.type}`,
              size: "sm",
              okVariant: "outline-danger",
              okTitle: this.$t("common.terms.yes"),
              cancelTitle: this.$t("common.terms.no"),
              cancelVariant: "success",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.deleteRow(index)
            }
          })
        } else {
          this.deleteRow(index)
        }
      },
      deleteRow(index) {
        let deletingID = structuredClone(this.items[index].id)
        this.$set(this.loadingDeleteObj, deletingID, true)
        this.$store.dispatch(`${this.setupType.namespace}${this.setupType.deleteItem}`,
          {
            serviceID: this.serviceID,
            groupID: this.groupID,
            requestID: this.requestID,
            requestItemID: this.items[index].id,
            direction: this.direction,
            type: this.type
          }
        ).then(() => {
          this.setupDefaultItem()
          delete this.loadingDeleteObj[deletingID]
        }).catch((err) => {
          delete this.loadingDeleteObj[deletingID]
        })
      },
      patchRequestItem(index, field) {
        this.setLoadingType(index, field, true)
        this.$store.dispatch(`${this.setupType.namespace}${this.setupType.patchItem}`,
          {
            serviceID: this.serviceID,
            groupID: this.groupID,
            requestID: this.requestID,
            direction: this.direction,
            type: this.type,
            requestItemID: this.items[index].id,
            new_value: this.items[index][field].id || this.items[index][field],
            field
          }
        ).then(() => {
          this.setPopoverOpen(false, index)
          this.setLoadingType(index, field, false)
          if (field == 'note' ){
            this.setSubmitButtonDisabled(false)
          }
        }).catch((err) => {
          this.setLoadingType(index, field, false)
        })
      },
      submitNote(event, index) {
        if (this.noteButtonDisabled[index]) return
        event.preventDefault()
        this.setSubmitButtonDisabled(true)
        setTimeout(() => {
          // this.setSubmitButtonDisabled(false)
        }, 1000)
        this.patchRequestItem(index, 'note')
      },
      setSubmitButtonDisabled(state, index) {
        this.$set(this.noteButtonDisabled, index, state)
      },
      typeSelected() {
        this.setFocus(true)
        if (this.defaultItem.key && this.defaultItem.value) {
          this.addRow()
        }
      },
      setFocus(state) {
        this.newItemFocus = state
      },
      getFieldTypeByID(id) {
        let index = this.fieldTypes.findIndex(el => el.id == id)
        return this.fieldTypes[index]
      },
      getDataTypeByID(id) {
        let index = this.dataTypes.findIndex(el => el.id == id)
        return this.dataTypes[index]
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      setPopoverOpen(state, index) {
        this.$set(this.popoverOpen, index, state)
      },
      timerAdd() {
        this.disableAdd = true
        setTimeout(() => {
          this.disableAdd = false
        }, 500)
      },
      isLoadingInput(id, field) {
        return this.loadingDeleteObj[id] || this.isLoadingInputEdit(id, field)
      },
      isLoadingInputEdit(id, field) {
        return this.loadingEditObj[id]?.[field]
      },
      setLoadingType(index, field, state) {
        if (!this.loadingEditObj[this.items[index]]) {
          this.loadingEditObj[this.items[index].id] = new Object()
        }
        this.$set(this.loadingEditObj[this.items[index].id], field, state) 
      }
    },
  }
</script>

<style lang="scss" scoped>
 
</style>

<style lang="scss">
 
.mapper-list {
  .mapper-input-list {
    .mapper-input {
      border: 1px solid #3B4253 !important;
      border-radius: 5px !important;
      background: #283046 !important;
    }
  }
  .mapper-input-plus {
    opacity: 0.5 !important;
  }
  .mapper-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #D0D2D6;
  }
  .delete-icon-button {
    transition: all 0.2s ease !important;
    color: #82868b !important;
    &:hover {
      color: #eb4a4a !important;
    }
  }
  .description-icon-button {
    transition: all 0.2s ease !important;
    color: #82868b !important;
    &:hover {
      color: #4abdeb !important;
    }
  }
  .add-button {
    transition: all 0.2s ease !important;
    color: #82868b !important;
    &:hover {
      opacity: 1 !important;
      color: #12e066 !important;
    }
  }
}
</style>